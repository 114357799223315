.div-0{
    text-align: center;
}.rotate-0{
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}
.rotate-90{
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.rotate-90-h{
    width: auto;
    /* max-width: 100%; */
    height: auto;
    margin: 0 auto;
    text-align: center;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}